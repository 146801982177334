import { Controller } from "@hotwired/stimulus"

const isVisible = (element) => {
  const isElementDisplayed = window.getComputedStyle(element).display !== 'none';
  const isElementDrawn = !!( element.offsetWidth || element.offsetHeight || element.getClientRects().length );

  return isElementDisplayed && isElementDrawn;
}

export default class PopoverController extends Controller {
  connect() {
    this.trigger = this.element.querySelector('.popover--trigger');
    this.closeBtn = this.element.querySelector('.popover--close');
    this.menu = this.element.querySelector('.popover--menu');

    this.boundHandleClickAway = this.handleClickAway.bind(this);

    this.trigger.addEventListener('click', (event) => {
      event.stopPropagation();
      this.show();

      document.addEventListener('click', this.boundHandleClickAway);
    });

    this.closeBtn.addEventListener('click', (event) => {
      event.stopPropagation();
      this.hide();

      document.addEventListener('click', this.boundHandleClickAway);
    });
  }

  handleClickAway(event) {
    this.onClickAway(event);
  }

  onTriggerClick() {
    this.toggle();
  }

  onClickAway(event) {
    const elementContainsTarget = this.element.contains(event.target);
    const fixedInset = event.target.className == "fixed inset-0 z-50";

    if (fixedInset || !elementContainsTarget) {
      this.hide();
    }
  }

  toggle() {
    if (isVisible(this.menu)) {
      this.hide();
    } else {
      this.show();
    }
  }

  show() {
    this.menu.classList.replace('hidden', 'show');
  }

  hide() {
    this.menu.classList.replace('show', 'hidden');
    document.removeEventListener('click', this.boundHandleClickAway);
  }
}
